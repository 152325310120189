import request from '../utils/requrst'

export function getBmi(data) {
  return request({
    url: '/api/tool/calculateBmi',
    method: 'POST',
    data
  })
}


// 获取code
export function getAccountOauthCodes(params) {
  return request({
    url: "/api/wxOffical/officalAccountOauth",
    method: "GET",
    params
  });
  
}

export function getQrcode(params) {
  return request({
    url: '/api/promotion/get_qrcode',
    method: 'get',
    params
  })
}

// 获取推广二维码
export function getTxQrcode(params) {
  return request({
    url: '/api/promotion/get_tx_qrcode',
    method: 'get',
    params
  })
}
// 腾讯瑜伽二维码

export function getTxYjQrcode(params) {
  return request({
    url: '/api/promotion/get_yj_tx_qrcode',
    method: 'get',
    params
  })
}
// 用户打开链接信息收集
export function reportTrends(data) {
  return request({
    url: '/api/sharing/report_trends',
    method: 'POST',
    data
  })
}
// 微信客服链接
export function getWxCsad(qrId) {
  return request({
    url: '/api/promotion/kfLink',
    method: 'post',
    params:qrId
  })
}

// 获取验证码
export function getVerify(data) {
  return request({
    url: '/api/promotion/verify',
    method: 'POST',
    data
  })
}
// 網頁授權
export function getTgUnionid(params) {
  return request({
    url: '/api/tgGetUnionid',
    method: 'GET',
    params
  })
}
export function getTgUnifyPayH5(data) {
  return request({
    url: "/api/order/tg_unifyPayH5",
    method: "POST",
    data
  });
}

// 获取支付结果
export function getTgOrderInfo(params) {
  return request({
    url: "/api/get_tg_order_info",
    method: "GET",
    params
  });
}