<template>
  <div class="bigDiv">
    <div v-if="result">
      <div class="topImg">
        <img src="../../assets/images/BIM/BIMBGC.png" alt="" />
      </div>
      <div class="topAbox">
        <div class="topTIt">
          <div class="left">计算结果</div>
          <div class="right">
            BMI= <span class="bim">{{ this.bmiResult.bmi }}</span>
            <span class="jiankang">{{ this.bmiResult.status_text }}</span>
            <div class="red"></div>
          </div>
        </div>
        <div class="neirong">
          <div class="left">
            <img src="../../assets/images/BIM/ren.jpg" alt="" v-if="sex == 1" />
            <img src="../../assets/images/BIM/nvren.png" alt="" v-if="sex == 2" />
          </div>
          <div class="right">
            <div :class="{textActive: bmiResult.bmi <= 18.4}">
              <span class="textColor" style="background-color: #cccccc"></span> 偏瘦
            </div>
            <div :class="{textActive: bmiResult.bmi >= 18.5 && bmiResult.bmi <= 23.9}">
              <span class="textColor" style="background-color: #66cc00"></span> 正常
            </div>
            <div :class="{textActive: bmiResult.bmi >= 24 && bmiResult.bmi <= 27.9}">
              <span class="textColor" style="background-color: #ffff00"></span> 过重
            </div>
            <div :class="{textActive: bmiResult.bmi >= 28}">
              <span class="textColor" style="background-color: #ff9900"></span> 肥胖
            </div>
          </div>
        </div>
      </div>
      <div class="topAbox">
        <div class="topTIt tit">瘦身建议</div>
        <div class="neirong">
          <div class="jianyi">
            <img src="../../assets/images/BIM/ren.jpg" alt="" v-if="sex == 1" />
            <img src="../../assets/images/BIM/nvren.png" alt="" v-if="sex == 2" />
            <div class="shencai dangqian">当前{{ bmiResult.status_text }}</div>
          </div>
          <div class="wenzi">
            <div>{{ bmiResult.proposal }}</div>
            <div><img src="../../assets/images/BIM/jt.png" alt="" /></div>
          </div>
          <div class="jianyi">
            <img src="../../assets/images/BIM/ren.jpg" alt="" v-if="sex == 1" />
            <img src="../../assets/images/BIM/nvren.png" alt="" v-if="sex == 2" />
            <div class="shencai">目标黄金身材</div>
          </div>
        </div>
      </div>
      <div class="topAbox boxs">
        <div class="tit">结果解读</div>
        <div class="text">
          {{ bmiResult.status_text }}
        </div>
        <div class="text">
          {{ bmiResult.results }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="ImgBOx">
        <div class="jisuan">计算中~</div>
        <img src="../../assets/images/BIM/nanGif.gif" alt="" v-if="sex == 1" />
        <img src="../../assets/images/BIM/nvGif.gif" alt="" v-if="sex == 2" />
        <div class="bto">身体扫描中~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWechatConfig } from "@/api/yangsheng";
import { getBmi } from "@/api/bimApi";
import { Toast } from "vant";
import { getAccountOauthCodes } from "@/api/components";

export default {
  name: "",
  props: {},
  components: {},
  data () {
    return {
      result: false,
      sex: "",
      bmiResult: "",
      zhuangtai: [
        { name: "偏瘦", color: "#cccccc" },
        { name: "正常", color: "#66cc00" },
        { name: "过重", color: "#ffff00" },
        { name: "肥胖", color: "#ff9900" },
      ],
    };
  },
  async created () {
    this.sex = this.$route.query.sex;
    const token = localStorage.getItem("token");
    if (!token) {
      console.log(123);
      //  await this.getAccountOauthCodes()
    }
    this.getBmi();
  },
  methods: {
    async getBmi () {
      try {
        const query = {
          sex: this.$route.query.sex,
          age: this.$route.query.age,
          height: this.$route.query.height,
          weight: this.$route.query.weight,
        };
        const { data } = await getBmi(query);
        this.bmiResult = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getAccountOauthCodes () {
      try {
        const data = {
          company_id: 1,
          code: this.$route.query.code,
        };
        const res = await getAccountOauthCodes(data);
        localStorage.setItem("token", res.data.data.token);
      } catch (error) {
        Toast.fail("缺少必要信息,请重新获取!");
        console.log(error);
        localStorage.removeItem("token");

        this.$router.replace({
          path: "/bmiIndex",
        });
      }
    },
    getWechatConfig () {
      const query = {
        company_id: 1,
        url: window.location.href,
      };
      getWechatConfig(query).then((res) => {
        const agent = res.data.data;
        if (res.data.code === 200) {
          this.$nextTick(() => { });
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature, // 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData,'onMenuShareAppMessage",
              "onMenuShareTimeline",
            ], // 必填，需要使用的JS接口列表
          });

          this.share();
        }
      });
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: "营养食疗BMI计算器", // 分享标题
            link: "https://kf.hshwhkj.com/bmiIndex", //这里可带参数，必须是同配置的JS安全域名一致
            desc: "快速测评BMI", // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { },
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: "营养食疗BMI计算器", // 分享标题
            link: "https://kf.hshwhkj.com/bmiIndex", //这里可带参数，必须是同配置的JS安全域名一致
            desc: "快速测评BMI", // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { },
          });
        });
        wx.error((res) => {
          console.log("微信分享错误信息：", res);
        });
      });
    },
  },
  mounted () {
    this.getWechatConfig();
    setTimeout(() => {
      this.result = true;
    }, 1500);
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.tit {
  font-weight: 700;
  font-size: 22px;
}
.jianyi {
  margin-left: 10px;
}
.bigDiv {
  width: 100%;
  min-height: 667px;
  overflow: hidden;
  background-color: #f8f8f8;
}
.topImg {
  width: 100%;
  position: absolute;
  img {
    width: 100%;
    object-fit: contain;
  }
}
.boxs {
  min-height: 30px;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
}
.topAbox {
  position: relative;
  z-index: 999;
  width: 320px;
  margin: 10px auto;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  min-height: 370px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  .topTIt {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .left {
      font-size: 22px;
      font-weight: 700;
    }
    .right {
      width: 170px;
      font-size: 18px;
      position: relative;
      top: 1px;
      color: #666666;
      font-weight: 700;
      .bim {
        font-size: 22px;
      }
      .jiankang {
        font-size: 14px;
      }
      .red {
        position: absolute;
        width: 170px;
        height: 5px;
        border-radius: 50px;
        background: #f98588;
      }
    }
  }
  .neirong {
    display: flex;
    align-items: center;
    .jianyi {
      height: 260px;
      margin-left: 30px;
      margin-top: 10px;
      img {
        height: 100%;
      }
      .shencai {
        font-size: 14px;
        color: #555555;
        position: relative;
        left: -10px;
        width: 90px;
      }
      .dangqian {
        position: relative;
        left: 5px;
      }
    }
    .left {
      height: 260px;
      margin-left: 60px;
      margin-top: 10px;
      img {
        height: 100%;
      }
    }
    .wenzi {
      width: 110px;
      text-align: center;
      margin-top: -20px;
      font-weight: 700;
      font-size: 20px;
      position: relative;
      left: 13px;
      top: -20px;
    }
    .right {
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      div {
        margin: 3px;
      }
      .textColor {
        width: 16px;
        height: 6px;
        border-radius: 50px;
        display: inline-block;
        position: relative;
        top: -3px;
      }
      .textActive {
        font-weight: 700;
        font-size: 22px;
      }
    }
  }
  .text {
    box-sizing: border-box;
    margin-bottom: 10px;
    color: #555;
    margin-top: 10px;
  }
}
.ImgBOx {
  width: 340px;
  margin: 10px auto;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  min-height: 570px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  text-align: center;
  position: relative;
  top: 40px;
  img {
    width: 120px;
    height: 400px;
    object-fit: cover;
    display: inline-block;
    position: relative;
    top: 80px;
  }
  .jisuan {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 28px;
    font-weight: 700;
  }
  .bto {
    font-size: 18px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%);
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    margin: 0 auto;
    .topImg {
      width: 750px;
      position: absolute;
      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }
    }
    .topAbox {
      position: relative;
      z-index: 999;
      width: 320px;
      margin: 10px auto;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      min-height: 370px;
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 30px;
      .topTIt {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .left {
          font-size: 22px;
          font-weight: 700;
        }
        .right {
          width: 170px;
          font-size: 18px;
          position: relative;
          top: 1px;
          color: #666666;
          font-weight: 700;
          .bim {
            font-size: 22px;
          }
          .jiankang {
            font-size: 14px;
          }
          .red {
            position: absolute;
            width: 170px;
            height: 5px;
            border-radius: 50px;
            background: #f98588;
          }
        }
      }
      .neirong {
        display: flex;
        align-items: center;
        .jianyi {
          height: 260px;
          margin-left: 20px;
          margin-top: 10px;
          img {
            height: 100%;
          }
          .shencai {
            font-size: 14px;
            color: #555555;
            width: 90px;
            position: relative;
            left: -10px;
          }
          .dangqian {
            position: relative;
            left: 8px;
          }
        }
        .left {
          height: 260px;
          margin-left: 60px;
          margin-top: 10px;
          img {
            height: 100%;
          }
        }
        .wenzi {
          width: 100px;
          text-align: center;
          margin-top: -20px;
          font-weight: 700;
          font-size: 20px;
          position: relative;
          left: 13px;
          top: -20px;
        }
        .right {
          margin-left: 60px;
          display: flex;
          flex-direction: column;
          div {
            margin: 3px;
          }
          .textColor {
            width: 16px;
            height: 6px;
            border-radius: 50px;
            display: inline-block;
            position: relative;
            top: -3px;
          }
          .textActive {
            font-weight: 700;
            font-size: 22px;
          }
        }
      }
      .text {
        box-sizing: border-box;
        margin-bottom: 10px;
        color: #555;
        margin-top: 10px;
      }
    }
    .boxs {
      min-height: 30px;
      padding: 20px;
      overflow: hidden;
      box-sizing: border-box;
    }
    .tit {
      font-weight: 700;
      font-size: 22px;
    }
    .jianyi {
      margin-left: 10px;
    }
  }
  .ImgBOx {
    width: 340px;
    margin: 10px auto;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    min-height: 570px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    top: 40px;
    img {
      width: 120px;
      height: 400px;
      object-fit: cover;
      display: inline-block;
      position: relative;
      top: 80px;
    }
    .jisuan {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 28px;
      font-weight: 700;
    }
    .bto {
      font-size: 18px;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
</style>
