// 卡片管理模块
import request from '../utils/requrst'



// 问题
export function yangshengQuestion(params) {
  return request({
    url: '/api/question/list',
    method: 'get',
    params
  })
}
// 点击提交

export function yangshengQuestionAdd(data) {
  return request({
    url: '/api/question/answer',
    method: 'post',
    data
  })
}
// 获取微信jsdk
export function getWechatConfig(params) {
  return request({
    url: '/api/getWechatConfig',
    method: 'get',
    params
  })
}