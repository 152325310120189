import request from '../utils/requrst'

export function getBmi(data) {
  return request({
    url: '/api/tool/calculateBmi',
    method: 'POST',
    data
  })
}




